import React, { useEffect, useState } from "react";
import "./about.css";
import D from "../asset/Upload image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import NAV from "../compnent/navbar";

export default function About() {
  const Studentname = localStorage.getItem("Fullname");
  const id = localStorage.getItem("info1");
  console.log(id)
  const [Name, setname] = useState('');
  const [age, setAge] = useState('');
  const [experience, setExperirence] = useState('');
  const [certification, setCertification] = useState('');
  const [aabout, setaabout] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const token1 = localStorage.getItem("token")
    fetch(`https://api.mastertattooinstitute.com/v1/user/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,

      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setname(data.firstName);
        setAge(data.age);
        setExperirence(data.experience);
        setCertification(data.certification);
        setaabout(data.about);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }, [id]);
  const ProfilePic = localStorage.getItem("prifilepicture");

  const sername = localStorage.getItem("username");
  return (
    <>
      <div className="bg-exlpor">
        <NAV />
        <br></br>
        <br></br>
        <br></br>
        {loading ? (
          <div class="container1">
            <div class="banner">
              LOADING
              <div class="banner-left"></div>
              <div class="banner-right"></div>
            </div>
          </div>


        ) : (
          <div>
            <div className="backtodashboard" style={{ marginTop: '80px' }}>
              <Link to={'/home'} style={{ textDecoration: 'none' }}>
                <div style={{ display: 'flex', marginLeft: '10px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M17 20.7816L12 14.8908L17 9M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <h3 className="back-texture" style={{ color: 'white', margin: '8px' }}>Back to Home</h3>
                </div>
              </Link>
            </div>
            <p className="explor">{Studentname}</p>
            <br></br>
            <div className="nav-explor">
              <p className="isactive1">
                <Link className="nav-explor" to={`/${sername}/about`}>
                  ABOUT
                </Link>
              </p>
              <p>
                <Link className="nav-explor" to={`/${sername}/tatoos`}>
                  TATTOOS
                </Link>
              </p>
              <p>
                <Link className="nav-explor" to={`/${sername}/bodypricing`}>
                  BODY PIERCING
                </Link>
              </p>
              <p>
                <Link className="nav-explor" to={`/${sername}/explore`}>
                  ART
                </Link>
              </p>


              <p>
                <Link className="nav-explor" to={`/${sername}/blog`}>
                  GROUPS
                </Link>
              </p>
              <p>
                <Link className="nav-explor" to="/location">
                  CONTACT
                </Link>
              </p>
            </div>
            <span className="abc">
              <p className="line1"></p>
            </span>
            <div className="line">
              <hr style={{ color: "white" }}></hr>
            </div>
            <div className="icon-expo">
              <p className="shear">
                <FontAwesomeIcon
                  className="fa"
                  icon={faShare}
                  style={{ color: "white" }}
                />{" "}
                Share
              </p>
              <p className="shear">
                <FontAwesomeIcon
                  className="fa"
                  icon={faPenToSquare}
                  style={{ color: "white" }}
                />{" "}
                Edit Portfolio
              </p>
            </div>
            <hr style={{ color: "#FFFFFF66" }}></hr>
            <br></br>
            <div className="about-self">
              <div class="grid-thirds">
                <div class="container-1">
                  {ProfilePic ? (
                    <img src={'https://api.mastertattooinstitute.com/' + ProfilePic} />) : (
                    <img src={D} />
                  )}
                </div>
                <div class="container-2">
                  <div className="self-text">
                    <p className="name2">Name <span className="name1">: {Name}</span></p>
                    <p className="name2">Age <span className="name1">: {age} years old</span></p>
                    <p className="name2">Experience <span className="name1">: {experience}</span></p>
                    <p className="name2">Certification <span className="name1">: {certification}</span></p>
                  </div>
                  <div className="self-des" >
                    <p className="name1">
                      {aabout}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
          </div>
        )}
      </div>
    </>
  );
}
