import React,{useEffect, useState} from "react";
import "./resul.css";
import NAv from "../compnent/navbar";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { faBookBookmark, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link,useNavigate, useParams } from "react-router-dom";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";


ChartJS.register(ArcElement, Tooltip, Legend);
export default function Result() {
  const{courseId}=useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const data = {
    datasets: [
      {
        data: [8, 6],
        backgroundColor: ["red", "#283C55"],
        borderColor: ["red", "#283C55"],
        text: "acsd",
      },
    ],
  };
  const options = {};
  const {studentId}=useParams();
  const{itemId}=useParams();
  const{weekId}=useParams();
  const[courseTitle,setTitle]=useState('');
  const[category,setCategory]=useState('')
  const[instructor,setInstructor]=useState('');

  useEffect(() => {
    // Fetch the initial course data (for the first week)
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,

      },
    })
      .then((response) => response.json())
      .then((data) => {
       setTitle(data.title);
       setCategory(data.category);
       setInstructor(data.instructor);
      })
      .catch((error) => {
        console.error("Error fetching course data: ", error);
      });
  }, []);
  const[assignmentCheck,setAssignmentCheck]=useState('')
  useEffect(() => {
    // Fetch data when the component is mounted
    const token1 = localStorage.getItem('token');
    const requestData = {
      week: weekId,
      course: courseId,
    };

    fetch('https://api.mastertattooinstitute.com/api/v1/studentAssignment/course/week/students/fetch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token1}`,
      },
      body: JSON.stringify(requestData),
    })
      .then(response => response.json())
      .then(data => {
        setAssignmentCheck(data[0]);
        console.log(data[0])
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [weekId, courseId]);
  const renderStars = (score) => {
    const stars = [];
    for (let i = 0; i < score; i++) {
      stars.push(
        <i
          key={i}
          className="fa-solid fa-star fa-2xl"
          style={{ color: "#FF912C" }}
        ></i>
      );
    }
    return stars;
  };
  return (
    <div className="bg_image2">
      <NAv />
      <br></br>
      <div className="inner-div" style={{marginTop:'140px'}}>
        <br></br>
        <div className="back-button-div" style={{ margin: "20px" }}>
          <a href="" className="back-button">
            <FontAwesomeIcon
              className="fa"
              icon={faCaretLeft}
              style={{ color: "#34465c96" }}
            />{" "}
          </a>
          <Link className="back-link" to={`/coursedetail/${courseId}/${itemId}`}>
            Back to Assigned Course
          </Link>
        </div>
        <br></br>
        <div className="grade">
          <h2
            className="assin"
            style={{ marginTop: "25px", marginLeft: "10px" }}
          >
            Course Title - {courseTitle}
          </h2>
          <div className="per">
            <div style={{ display: "flex" }}>
              <div style={{ width: "30%", height: "30%" }}>
                <Doughnut data={data} options={options}></Doughnut>
              </div>
              <p
                style={{
                  marginLeft: "10px",
                  fontSize: "15px",
                  marginTop: "30px",
                }}
              >
                Assignment Progress
              </p>
              <br></br>
            </div>
            <p className="num">{assignmentCheck?.assignment?.score}/5 Assignment</p>
          </div>
        </div>
        <div className="boo">
          <p className="pic" style={{ marginLeft: "20px", color: "#283C5566" }}>
            {" "}
            <FontAwesomeIcon
              className="fa"
              icon={faBookBookmark}
              style={{ color: "#283C5566" }}
            />{" "}
            Category - &nbsp;{category}
          </p>

          <p style={{ marginLeft: "20px", color: "#283C5566" }}>
            {" "}
            <FontAwesomeIcon
              className="fa"
              icon={faUser}
              style={{ color: "#283C5566" }}
            />{" "}
            Instructor - &nbsp; {instructor.firstName}
          </p>

          <p style={{ marginLeft: "20px", color: "#283C5566" }}>
            {" "}
            4 Reviews -
            <FontAwesomeIcon
              className="fa"
              icon={faStar}
              style={{ color: "gold" }}
            />
            <FontAwesomeIcon
              className="fa"
              icon={faStar}
              style={{ color: "gold" }}
            />
            <FontAwesomeIcon
              className="fa"
              icon={faStar}
              style={{ color: "gold" }}
            />
            <FontAwesomeIcon
              className="fa"
              icon={faStar}
              style={{ color: "gold" }}
            />
          </p>
        </div>
        <hr></hr>
       
        <div className="upload">
          <div className="upload_div">
            <svg
              style={{ marginTop: "20px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
            >
              <path
                d="M54 39.9896L30 40.0312"
                stroke="#283C55"
                stroke-width="4.8"
                stroke-linecap="round"
              />
              <path
                d="M12 12.8C12 10.149 14.149 8 16.8 8H58.2622C59.3851 8 60.4724 8.39368 61.335 9.11254L72 18L82.2729 26.5607C83.3672 27.4727 84 28.8237 84 30.2482V83.2C84 85.851 81.851 88 79.2 88H16.8C14.149 88 12 85.851 12 83.2V12.8Z"
                fill="white"
              />
              <path
                d="M66 72H30"
                stroke="#283C55"
                stroke-width="4.8"
                stroke-linecap="round"
              />
              <path
                d="M66 56H30"
                stroke="#283C55"
                stroke-width="4.8"
                stroke-linecap="round"
              />
            </svg>
            <br/>
            <p style={{ textAlign: "center", color: "white" }} className="pnh">
              {assignmentCheck?.assignment?.attachment?.fileName}
            </p>
          </div>
          <br></br>
          <br></br>
          
          <br></br>
          <p style={{ textAlign: "center", color: "white" }} className="pnh">
              {assignmentCheck?.assignment?.review}
            </p>
          <br></br>
          <p style={{ color: "rgba(255, 255, 255, 0.70)" }}>Grading Tokens</p>
          <br/>
          <div className="star">
          {assignmentCheck?.assignment?.score === 0 ? (
  <h5>"The assignment cannot be checked at the moment"</h5>
) : (
  renderStars(assignmentCheck?.assignment?.score)
)}
          </div>
        </div>
        <br></br>
        
        
      </div>

      <br></br>
    </div>
  );
}
