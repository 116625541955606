import React, { useEffect ,useState} from "react";
import "./navbr.css";
import LOGO from "../asset/logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faB, faHome, faInbox, faTableColumns, faUser } from "@fortawesome/free-solid-svg-icons";
import { faFileText } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import IMG from "../asset/Ellipse 3.png";
import { Link, useNavigate,useLocation } from "react-router-dom";
import three from "../asset/three.png";
import socket from "./socket";

export default function Navbar() {

  const Studentname = localStorage.getItem("Fullname");
  const ProfilePic = localStorage.getItem("prifilepicture");
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('/home');
  const location = useLocation();

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('prifilepicture');
    localStorage.removeItem('Fullname');
    localStorage.removeItem('email1');
    localStorage.removeItem('info');
    localStorage.removeItem('info1');
    localStorage.removeItem('username');
    socket.disconnect();
    console.log("disconnect")
    navigate("/");
  }
  return (
    <div className="abc1">
      <nav class="navbar navbar-expand-lg bg-body-tertiary cc">
        <div class="container-fluid">
          <img src={LOGO} className="navbar-brand Logo-mti" />
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><img src={three} /></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 firstLinks">
            <li className={`nav-item ${location.pathname === '/home' ? 'active' : ''}`}>
              <Link to="/home" className="nav-link Link-text Linker">
                <FontAwesomeIcon
                  className="fa"
                  icon={faHome}
                  style={{ color: "#85A3AB", marginBottom: '1px' }}
                />
                &nbsp;Home
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/dash' ? 'active' : ''}`}>
              <Link to="/dash" className="nav-link Link-text Linker">
                <FontAwesomeIcon
                  className="fa"
                  icon={faTableColumns}
                  style={{ color: "#85A3AB" }}
                />
                &nbsp;Dashboard
              </Link>
            </li>
            <li className={`nav-item ${location.pathname === '/course' ? 'active' : ''}`}>
              <Link to="/course" className="nav-link Link-text Linker">
                <FontAwesomeIcon
                  className="fa"
                  icon={faFileText}
                  style={{ color: "#85A3AB" }}
                />
                &nbsp;Courses
              </Link>
            </li>
            </ul>
            <span class="navbar-text">
              <div className="nav-flex">
                <div className="Icons">

                <div class="btn-group" style={{ cursor: 'pointer' }}>
                    <a data-bs-toggle="dropdown" aria-expanded="false">
                      <a style={{ border: "1px solid #85A3AB", padding: '4px 8px', marginRight: '15px' }}>
                        <FontAwesomeIcon
                          className="fa"
                          icon={faEnvelope}
                          style={{ color: "#85A3AB" }}
                        />
                      </a>

                    </a>
                    <ul class="dropdown-menu" style={{margin:'10px 0 0 0',padding:'0 0 0 0'}}>
                      <li class="dropdown-item notification"> <FontAwesomeIcon
                          className="fa"
                          icon={faEnvelope}
                          style={{ color: "#283C55" }}
                        /> New Messages</li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><Link class="dropdown-item viewAll" to={'/inbox'}>ViewAll</Link></li>
                    </ul>

                  </div>
                  <div class="btn-group" style={{ cursor: 'pointer' }}>
                    <a data-bs-toggle="dropdown" aria-expanded="false">
                      <a style={{ border: "1px solid #85A3AB", padding: '4px 8px', marginRight: '15px' }}>
                        <FontAwesomeIcon
                          className="fa"
                          icon={faBell}
                          style={{ color: "#85A3AB" }}
                        />
                      </a>

                    </a>
                    <ul class="dropdown-menu" style={{margin:'10px 0 0 0',padding:'0 0 0 0'}}>
                      <li class="dropdown-item notification"> <FontAwesomeIcon
                          className="fa"
                          icon={faBell}
                          style={{ color: "#283C55" }}
                        /> Notification</li>
                      <li><a class="dropdown-item" href="#">Another action</a></li>
                      <li><a class="dropdown-item" href="#">Something else here</a></li>
                      <li><hr class="dropdown-divider" /></li>
                      <li><a class="dropdown-item viewAll" href="#">ViewAll</a></li>
                    </ul>

                  </div>

                  {/* <a style={{ border: "1px solid #85A3AB", padding: '3px 8px 6px 8px', marginRight: '15px' }}>
                    <FontAwesomeIcon
                      className="fa"
                      icon={faSearch}
                      style={{ color: "#85A3AB" }}
                    />
                  </a> */}
                </div>
                <div className="img-te">
                  <div class="dropdown" style={{ cursor: 'pointer' }}>
                    <a data-bs-toggle="dropdown" aria-expanded="false" className="img-te" style={{ textDecoration: 'none' }}>
                      {ProfilePic ? (
                        <img

                          src={'https://api.mastertattooinstitute.com/' + ProfilePic} className="prof-pic"
                          alt="User Profile"
                        />) : (
                        <img

                          src={IMG}
                          alt="User Profile"
                        />
                      )}
                      <h1 className="name-nav">{Studentname}
                        <span className="span-name">Student</span></h1>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="7" viewBox="0 0 14 7" fill="none" className="arrow-nav">
                        <path d="M13 1L7 6L1 1" stroke="#283C55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                    <ul class="dropdown-menu" style={{ cursor: 'pointer',width:'240px' }}>
                      <li>
                    <Link to="/home" class="dropdown-item" >
                <FontAwesomeIcon
                  className="fa"
                  icon={faHome}
                  style={{ color: "#283C55", marginBottom: '1px' }}
                />
                &nbsp;Home
              </Link>
            </li>
            <li >
              <Link to="/dash" class="dropdown-item" >
                <FontAwesomeIcon
                  className="fa"
                  icon={faTableColumns}
                  style={{ color: "#283C55" }}
                />
                &nbsp;Dashboard
              </Link>
            </li>
            <li>
              <Link to="/course"  class="dropdown-item">
                <FontAwesomeIcon
                  className="fa"
                  icon={faFileText}
                  style={{ color: "#283C55" }}
                />
                &nbsp;Courses
              </Link>
            </li>
                      <li><Link class="dropdown-item" to={'/profile'}><FontAwesomeIcon
                        className="fa"
                        icon={faUser}
                        style={{ color: "#283C55" }}
                      /> Profile</Link></li>
                      <li class="dropdown-item" onClick={handleLogout}><FontAwesomeIcon
                        className="fa"
                        icon={faArrowRightFromBracket}
                        style={{ color: "#283C55" }}
                      />  LogOut</li>
                      
             
                    </ul>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
}
