import React,{useEffect} from "react";
import "./blog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Link,useNavigate } from "react-router-dom";
import NAV from "../compnent/navbar";
import emailjs from '@emailjs/browser';
import { alertTitleClasses } from "@mui/material";
export default function Location() {
  const Studentname=localStorage.getItem("Fullname");
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     navigate("/");
  //   }
  // }, []);
  const handleEmailSubmit = (e) => {
   e.preventDefault();
   emailjs.sendForm('service_0v01bqo','template_bupbixt',e.target,'dsOwCTFqR8Hh6Bpfw');
   alert('Your Form Successfully send')
   window.location.reload();
  };
  const sername=localStorage.getItem("username");
  return (
    <div>
      <div className="bg-exlpor">
        <NAV />
        <br></br>
        <br></br>
        <br></br>
        <div className="backtodashboard" style={{marginTop:'80px'}}>
      <Link to={'/home'} style={{ textDecoration: 'none' }}>
              <div style={{display:'flex',marginLeft:'10px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <path d="M17 20.7816L12 14.8908L17 9M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                <h3 className="back-texture" style={{color:'white',margin:'8px'}}>Back to Home</h3>
              </div>
            </Link>
            </div>
        <p className="explor">{Studentname}</p>
        <br></br>
        <div className="nav-explor">
        <p>
      <Link className="nav-explor" to={`/${sername}/about`}>
            ABOUT
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to={`/${sername}/tatoos`}>
            TATTOOS
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to={`/${sername}/bodypricing`}>
            BODY PIERCING
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to={`/${sername}/explore`}>
            ART
          </Link>
        </p>
        
        
        <p>
          <Link className="nav-explor" to={`/${sername}/blog`}>
            GROUPS
          </Link>
        </p>
        <p className="isactive1">
          <Link className="nav-explor" to="/location">
            CONTACT
          </Link>
        </p>
        </div>
        <span className="abc">
    <p className="line1"></p>
      </span>
        <div className="line">
          <hr style={{ color: "white" }}></hr>
        </div>
        <div className="icon-expo">
          <p className="shear">
            <FontAwesomeIcon
              className="fa"
              icon={faShare}
              style={{ color: "white" }}
            />{" "}
            Share
          </p>
          <p className="shear">
            <FontAwesomeIcon
              className="fa"
              icon={faPenToSquare}
              style={{ color: "white" }}
            />{" "}
            Edit Portfolio
          </p>
        </div>
        <hr style={{ color: "#FFFFFF66" }}></hr>
        <br></br>

        <div className="info-form">
          <form className="form-design" onSubmit={handleEmailSubmit}>
            <br></br>
            <h5 style={{ color: "white", margin: "10px" }}>Contact</h5>
            <h1 style={{ color: "white", margin: "10px" }}>Get In Touch</h1>
            <label style={{ color: "white", margin: "10px" }}>Your Name</label>
            <br></br>
            <input type="text" className="regis" id="name" />
            <br></br>

            <label style={{ color: "white", margin: "10px" }}>
              Your E-mail
            </label>
            <br></br>
            <input type="text" className="regis" id="emailFrom" name="email_from" />
            <br></br>
            <label style={{ color: "white", margin: "10px" }}>
              Your Message(optional)
            </label>
            <br></br>
            <textarea
              rows="4"
              cols="27"
              name="message"
              id="message"
              style={{
                margin: "10px",
                background: "#FF912C",
                color: "white",
                border: "0.5px solid white",
                padding: "5px",
                outline:'none'
              }}
              className="text-area"
            >
              {" "}
            </textarea>
            <br></br>
            <button className="form-button" style={{outline:'none'}}>Submit</button>
          </form>
        </div>
        <br></br>
        <br/>
      </div>
    </div>
  );
}
