import React,{useState,useEffect} from 'react'
import "./explo.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import R from "../asset/Upload image.png";
import D from "../asset/Rectangle 125 (1).png"
import F from "../asset/Rectangle 125.png";
import G from "../asset/Rectangle 126.png";
import H from "../asset/Rectangle 127.png";
import J from "../asset/Rectangle 129.png";
import { Link,useNavigate, useParams } from 'react-router-dom';
import NAV from "../compnent/navbar";
import axios from 'axios';

export default function Bodypricing() {
  const navigate = useNavigate();
  const {username}=useParams();
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     navigate("/");
  //   }
  // }, []);
  const Studentname=localStorage.getItem("Fullname");
  const id = localStorage.getItem("info1");
   
  const [selectedIma, setSelectedIma] = useState(null);

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files[0];
    setSelectedIma(files);
  };

  useEffect(() => {
    const stId = localStorage.getItem("info1");
    const body = "bodypercing";
    const token=localStorage.getItem("token");
 if(!selectedIma){
  console.log('no image select right now');
 }
 else{
    const uploadbodyPicture = async () => {
      try {
        const formData = new FormData();

    
        formData.append(
          'category', body
          
        );
        formData.append(
          
          'student',stId
        )
          formData.append('attachment', selectedIma);
      
        const response = await axios.post(
          "https://api.mastertattooinstitute.com/api/v1/portfolio",
          formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },});
          
        

        console.log(response.data);
        window.location.reload();
        setLoading(false);
      } catch (error) {
        console.error("Error uploading or updating profile picture:", error);
        setLoading(false);
      }
    };
  
    if (selectedIma) {
      uploadbodyPicture();
    }
    // window.location.reload();
  }

  }, [id, selectedIma]);

  const [images, setImages] = useState([]);
  const [category, setCategory] = useState("bodypercing");
  const stI= localStorage.getItem("username");
  const fetchImagesByCategory = () => {
    fetch(`https://api.mastertattooinstitute.com/api/v1/portfolio/category/${category}/student/${username}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setImages(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching images:", error.message);
        setLoading(false);
      });
  };
   useEffect(() => {
     fetchImagesByCategory();
    }, [category]);
    const sername=localStorage.getItem("username");
  return (
    <div className='bg-exlpor'>
      <NAV/>
      <br></br>
      <br></br>
      <br></br>
      {loading ? (
        <div class="container1">
          <div class="banner">
            LOADING
            <div class="banner-left"></div>
            <div class="banner-right"></div>
          </div>
        </div>


      ) : (
      <div>
      <div className="backtodashboard" style={{marginTop:'80px'}}>
      <Link to={'/home'} style={{ textDecoration: 'none' }}>
              <div style={{display:'flex',marginLeft:'10px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <path d="M17 20.7816L12 14.8908L17 9M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                <h3 className="back-texture" style={{color:'white',margin:'8px'}}>Back to Home</h3>
              </div>
            </Link>
            </div>
      <p className='explor'>{Studentname}</p>
      <br></br>
      <div className='nav-explor'>
      <p>
      <Link className="nav-explor" to={`/${sername}/about`}>
            ABOUT
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to={`/${sername}/tatoos`}>
            TATTOOS
          </Link>
        </p>
        <p className="isactive1">
          <Link className="nav-explor" to={`/${sername}/bodypricing`}>
            BODY PIERCING
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to={`/${sername}/explore`}>
            ART
          </Link>
        </p>
        
        
        <p>
          <Link className="nav-explor" to={`/${sername}/blog`}>
            GROUPS
          </Link>
        </p>
        <p>
          <Link className="nav-explor" to="/location">
            CONTACT
          </Link>
        </p>
      </div>
      <span className="abc">
    <p className="line1"></p>
      </span>
      <div className='line'>
      <hr style={{color:'white'}}></hr>
      </div>
      <div className='icon-expo'>
      <p className="shear"><FontAwesomeIcon className="fa" icon={faShare} style={{ color: "white" }} /> Share</p>
      <p className="shear"><FontAwesomeIcon className="fa" icon={faPenToSquare} style={{ color: "white" }} /> Edit Portfolio</p>
      </div>
      <hr style={{color:'#FFFFFF66'}}></hr>
      <br>
      </br>
      <div className="gallery-wrapper">
        <div className="gallery">
        <img
            src={R}
            alt="Image"
            onClick={handleImageClick}
          />
      
      {images.map((image, index) => (
            <img
              key={index}
              src={'https://api.mastertattooinstitute.com/'+images[index].image.fileName} // Assuming "url" is the property that holds the image URL
             
            />
          ))}
        </div>
      </div>
 <br></br>
 <input
        type="file"
        id="fileInput"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      </div>
      )}
    </div>
  )
}
