import React, { useState ,useEffect} from 'react';
import "./quiz.css";
import zoom_logo from "../asset/Zoom-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Quiz() {

  const { courseId } = useParams();
  const{quizId}=useParams();
  const{stId}=useParams();
  const{itemId}=useParams();
  const navigate=useNavigate();
  const [Data,setData]=useState("");
  const Token=localStorage.getItem("token");
  const [cover,setCover]=useState(null);
  const [question,setQuestion]=useState('');
  const [questionLength,setQuestionLenght]=useState('');
  const [questionId,setquesid]=useState('');
  const [newques,setnewQuestion]=useState('');
const [quesTitle,setTitle]=useState('');
const [quizDate,setquizDate]=useState('');
const [selectedWeek, setSelectedWeek] = useState('');
const [instructor,setInstructor]=useState('');
const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Function to make the API request
    const fetchData = async () => {
      try {
        // Replace 'your-api-endpoint' with the actual API endpoint
        const response = await axios.get(`https://api.mastertattooinstitute.com/api/v1/quiz/${quizId}`, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        
        // Set the fetched data in the state
        // console.log(response.data.length)
       setQuestionLenght(response.data.questions.length);
       setnewQuestion(response.data.questions);
       setCover(response.data.cover.fileName);
       setquizDate(response.data.course.category);
       setTitle(response.data.course.title);
       setquesid(response.data.questions[0].id);
       setLoading(false);
       
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [questionId]);
  useEffect(() => {
    // Fetch the initial course data (for the first week)
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,

      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInstructor(data.instructor.firstName);
        setLoading(false);
        
      })
      .catch((error) => {
        console.error("Error fetching course data: ", error);
        setLoading(false);
      });
  },);
 
  const weeks = Array.from({ length:questionLength }, (_, index) => index);

  const progressPercentage = ((selectedWeek + 1) / questionLength) * 100;


  
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust based on your formatting needs
  };
  // const [optionA,setOptionA]=useState('');
  // const [optionB,setOptionB]=useState('');
  // const [optionC,setOptionC]=useState('');
  const [answer,setAnswer]=useState('');
  const [questionImg,setQuesImg]=useState(null);
  const [options1,setOptions]=useState([]);
  const[questionObject,setquestionObject]=useState([]);

  useEffect(() => {
    // Function to make the API request
    const fetchData = async () => {
      try {
        // Replace 'your-api-endpoint' with the actual API endpoint
        const response = await axios.get(`https://api.mastertattooinstitute.com/api/v1/question/${questionId}`, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        
        // Set the fetched data in the state
        // console.log(response.data.length)
        setquestionObject(response.data);
        setQuestion(response.data.question)
        // setOptionA(response.data.options[0]);
        // setOptionB(response.data.options[1]);
        // setOptionC(response.data.options[2]);
         setOptions(response.data.options)
        setQuesImg(response.data.attachment.fileName);
        setLoading(false);
        
       
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [selectedWeek]);

  const handleWeekClick = (week) => {
    setSelectedWeek(week);
    setquesid(newques[week].id);

};

const [selectedAnswers, setSelectedAnswers] = useState([]);
const [selectedOption, setSelectedOption] = useState(null);
  // ... (other code)

  const handleAnswer = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Update the selectedAnswers state with the chosen answer
    setSelectedAnswers((prevAnswers) => [
      ...prevAnswers,
      { answer: selectedOption, quiz:quizId,studentQuiz:stId,question:questionObject },
    ]);
  };
  const handleSubmitQuiz = async () => {
    try {
      // Replace 'your-submit-api-endpoint' with the actual API endpoint
      const response = await axios.post(
        `https://api.mastertattooinstitute.com/api/v1/studentQuiz/submit`,
        selectedAnswers,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle the response as needed
      console.log('Quiz submission response:', response.data);
      
      navigate(`/quizresult/${courseId}/${response.data.score}/${itemId}`)
      setLoading(false);
    } catch (error) {
      console.error('Error submitting quiz:', error);
      setLoading(false);
    }
  };

  
    return (
      <>
      {loading ? (
        <div class="container1">
          <div class="banner">
            LOADING
            <div class="banner-left"></div>
            <div class="banner-right"></div>
          </div>
        </div>


      ) : (
        <div className="container tray">
          <div className="back-button-div">
            <a href="" className="back-button">
              <FontAwesomeIcon
                className="fa"
                icon={faCaretLeft}
                style={{ color: "#34465c96" }}
              />{" "}
            </a>
            <Link className="back-link" to={`/coursedetail/${courseId}/${itemId}`}>
              Back to Assigned Course
            </Link>
          </div>
          <div className="course-header">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="course-title">
                  <h2>Course Title - {quesTitle}</h2>
                </div>
                <div className="course-info">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="course-info-1">
                        <FontAwesomeIcon
                          className="fa"
                          icon={faBookmark}
                          style={{ color: "#34465c96" }}
                        />{" "}
                        <h2>Category - {quizDate}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="course-info-2">
                        <FontAwesomeIcon
                          className="fa"
                          icon={faUser}
                          style={{ color: "#34465c96" }}
                        />{" "}
                        <h2>Instructor - {instructor}</h2>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="course-info-3">
                        <h2>4 Reviews - </h2>
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="quiz-progress">
                  <div className="progress-wrapper">
                    <h2>
                    {selectedWeek + 1}/{questionLength} Question
                    </h2>
                    <h3>Quiz progress: {progressPercentage.toFixed(2)}%</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container" style={{marginTop:'5%'}}>
            <div className="row g-0">
             
          <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="week-list">
          <ul>
         
                          {weeks.map((week) => (
                            <li
                              key={week}
                              className={
                                week === selectedWeek ? "active-week" : ""
                              }
                              onClick={() => handleWeekClick(week)}
                            >
                              <h2>Question - {week+1}</h2>
                              <p>Updated on August 8, 2023 2:30PM</p>
                            </li>
                          ))}
                        </ul>
                        
                      </div>
            </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="quiz-area">
              <div className="quiz-wrapper">
                <h2>Question {selectedWeek + 1}</h2> 
                <p>{question}</p>
                {questionImg ?
                          (<img

                            src={'https://api.mastertattooinstitute.com/' +questionImg}
                            alt="Quiz Image"
                          />) : (
                            <img src={zoom_logo} alt="" />)}
                {options1?.map((option, index) => (
            <label key={index} style={{
              backgroundColor: selectedOption === option ? 'rgba(255, 64, 52, 0.20)' : 'transparent',
            }}> 
              <input
                type="radio"
                value={option}
                name="answer"
                onChange={() => handleAnswer(option)}
              />
              {option} <br />
            </label>
          ))}
              </div>
            </div>
          </div>
          </div>
          </div>
          <div className="container">
            <div className="fle">
              <button className="flesd"onClick={handleSubmitQuiz}>Finish Quiz</button>
            </div>
          </div>
         
        </div>
      )}
      </>
    );
  }
