import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import "./cdetail.css";
import zoom_logo from "../asset/Zoom-logo.png";
import img1 from "../asset/Frame 3619.png";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import NAV from "../compnent/navbar";
import Quiz from "./Quiz";
import axios from "axios";

export default function Coursedetail() {
  const { courseId } = useParams();
  console.log(courseId);
  const navigate = useNavigate();
  const { itemId } = useParams();

  const [instructor, setInstructor] = useState('');
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const navigate12 = useNavigate();
  const studentId = localStorage.getItem("info1");

  const Id = localStorage.getItem("info1");
  const [cover, setCover] = useState(null);
  let [selectedWeek, setSelectedWeek] = useState(0);
  const [courseDate, setcourseDate] = useState('');
  const [weekength, setWeekLenght] = useState('');
  const [weekid, setWeek1] = useState('');
  const [newweek, setnewWeek] = useState('');
  const [weekName, setName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [instructor1, setIntructor1] = useState('')
  const [category, setCategory] = useState('');
  const [imageName, setImageName] = useState('');
  const [lastWeeks, setLastWeek] = useState('');
  console.log(courseId)
  useEffect(() => {
    // Fetch the initial course data (for the first week)
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,

      },
    })
      .then((response) => response.json())
      .then((data) => {
        setcourseDate(data.createdAt);
        setWeekLenght(data.weeks.length);
        setnewWeek(data.weeks);
        setLastWeek(data.weeks[weeks.length - 1].id)
        setWeek1(data.weeks);
        setCover(data.cover);
        setCourseName(data.title);
        setIntructor1(data.instructor.firstName);
        setCategory(data.category);
        console.log(data)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching course data: ", error);
        setLoading(false);
      });
  }, [selectedWeek]);

  const weeks = Array.from({ length: weekength }, (_, index) => index);
  console.log(weeks)
  let [inks, setInks] = useState('');
  let [material, setMaterial] = useState('');
  let [needles, setNeedles] = useState('');
  const [weekdiscription, setDescription] = useState('');
  const [updated, setUpdated] = useState('');
  const [recording, setrecording] = useState(null)
  const [lectureTitle, setlectureTitle] = useState('');
  const [lecid, setlecid] = useState('');
  const [setLec, setnewlec] = useState('');
  const [WeekId1, setWeekid1] = useState('');
  const [recTime, setTime] = useState('');
  const [meetingLink1, setLink] = useState('');
  let [quizId, setQuizId] = useState('');
  let [quizWeekId, setQuizWeekId] = useState('');
  const [selectedImage, setImages] = useState(null);
  const [currentWeek, setCurrentWeek] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedWeeks, setUploadedWeeks] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Load uploadedWeeks from localStorage on component mount
    const storedIsUploaded = JSON.parse(localStorage.getItem("isUploaded")) || false;
    setIsUploaded(storedIsUploaded);
  }, []);

  // useEffect(() => {
  //   // Save uploadedWeeks to localStorage whenever it changes
  //   localStorage.setItem("", JSON.stringify(uploadedWeeks));
  // }, [uploadedWeeks]);

  const handleWeekClick = (week) => {
    setSelectedWeek(week);
    setQuizWeekId(newweek[week].id);
    setCurrentWeek(newweek[week].id);
    if (newweek[week].id === lastWeeks) {
      console.log("button");
      setNoButton(true);
    } else {
      console.log("no button");
      setNoButton(false); // Ensure to set the state for the false condition as well
    }
    if (uploadedWeeks.includes(newweek[week].id)) {
      setIsUploaded(true);
    } else {
      setIsUploaded(false);
    }
    // Function to make the API request

    const Token = localStorage.getItem("token")
    fetch(`https://api.mastertattooinstitute.com/api/v1/week/${newweek[week].id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Token}`,
      },

    })
      .then(response => response.json())
      .then(data => {
        if (data && data.quizes && data.quizes.length > 0) {
          setQuizId(data.quizes[0].id);
        }
        else {
          setQuizId(0);
        }
        fetch(`https://api.mastertattooinstitute.com/api/v1/lecture/${data.lectures[0].id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`,
          },

        })
          .then(response => response.json())
          .then(data => {
            setInks(data.inks);
            setDescription(data.description);
            setMaterial(data.standardMethods);
            setNeedles(data.needles);
            setUpdated(data.createdAt);
            setrecording(data.recording);
            setlectureTitle(data.name);
            setTime(data.meetingTime)
            setLink(data.meetingLink);
            console.log(data)
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

  };
  useEffect(() => {
    // Fetch the initial course data (for the first week)
    const token1 = localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,

      },
    })
      .then((response) => response.json())
      .then((data) => {
        setcourseDate(data.createdAt);

        setnewWeek(data.weeks);
        
        setWeek1(data.weeks);
        setCover(data.cover);
        setCourseName(data.title);
        setIntructor1(data.instructor.firstName);
        setCategory(data.category);
        setInks(data.weeks[0].lectures[0].inks);
        setDescription(data.weeks[0].lectures[0].description);
        setMaterial(data.weeks[0].lectures[0].standardMethods);
        setNeedles(data.weeks[0].lectures[0].needles);
        setUpdated(data.weeks[0].lectures[0].createdAt);
        setrecording(data.weeks[0].lectures[0].recording);
        setlectureTitle(data.weeks[0].lectures[0].name);
        setTime(data.weeks[0].lectures[0].meetingTime)
        setLink(data.weeks[0].lectures[0].meetingLink);
        

        fetch(`https://api.mastertattooinstitute.com/api/v1/lecture/${data.weeks[0].lectures[0].id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token1}`,
          },

        })
          .then(response => response.json())
          .then(data => {
            setrecording(data.recording);

            console.log(data)
            setLoading(false);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
        console.log(data)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching course data: ", error);
        setLoading(false);
      });
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust based on your formatting needs
  };
  const [isVisible, setIsVisible] = useState(false);
  const handleButtonClick12 = () => {
    setIsVisible(!isVisible);
  };
  const navigate1 = useNavigate();
  const [loader, setLoader] = useState(true)
  const [isConditionTrue1, setNoButton] = useState(false);
  const nav11 = useNavigate();
  const checkResult = () => {
    nav11(`/result/${courseId}/${studentId}/${itemId}/${quizWeekId}`)
  }
  const handleQuizClick = () => {
    const data = {
      // Your data to be sent in the request
      quiz: quizId,
      course: courseId,//student course id
      week: quizWeekId,
      student: studentId,
      studentCourse: itemId
    };
    const Token = localStorage.getItem("token");
    fetch('https://api.mastertattooinstitute.com/api/v1/studentQuiz', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Token}`,
        // Add any additional headers as needed
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(result => {
        // Handle the result from the API
        console.log(result);

        navigate1(`/quiz/${courseId}/${quizId}/${result.id}/${itemId}`)

      })
      .catch(error => {
        // Handle errors
        console.error('Error:', error);
        setLoader(false);


      });


  };

  if (loader === false) {
    // Show loader while data is being fetched or if there's no data
    return (
      <div class="container1">
        <Link className="back-link" to={`/dash`}>
          Back to Dashboard
        </Link>
        <div class="banner">
          No Quiz In This Week
          <div class="banner-left"></div>
          <div class="banner-right"></div>
        </div>
      </div>
    );
  }

  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };
  const handleFileChange = (event) => {
    const files = event.target.files[0];
    setImageName(files.name);
    setImages(files);

  };

  const uploadAssignment = () => {
    const token = localStorage.getItem("token");
    try {
      const formData = new FormData();


      formData.append(
        'week', quizWeekId
      );
      formData.append(

        'student', studentId
      )
      formData.append(

        'course', courseId
      )
      formData.append(
        'studentCourse', itemId
      )
      //student couse id
      formData.append('attachment', selectedImage);

      const response = axios.post(
        "https://api.mastertattooinstitute.com/api/v1/assignment",
        formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });



      console.log(response.data);
      alert("your assignment is uploaded")
      setIsUploaded(true);
      localStorage.setItem("isUploaded", JSON.stringify(true));
    } catch (error) {
      console.error("Error uploading assignment:", error);
      alert("your all ready upload the assignment")
    }
  };

  const handleCompleteCourse = async () => {
    const token = localStorage.getItem("token");
    if (currentWeek === lastWeeks) {
      try {
        const response1 = await fetch(`https://api.mastertattooinstitute.com/api/v1/studentCourse/${itemId}/markComplete`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response1.ok) {
          throw new Error('Network response was not ok');
        }

        // const result = await response.json();
        navigate12("/dash");
      } catch (error) {

      }

    }
    else {
      navigate12(`/coursedetil/${courseId}`)
      alert("please complete the course")
    }
  }


  return (
    <div>
      <NAV />
      {loading ? (
        <div class="container1">
          <div class="banner">
            LOADING
            <div class="banner-left"></div>
            <div class="banner-right"></div>
          </div>
        </div>


      ) : (
        <div className="container tray" style={{ marginTop: '120px' }}>
          <div className="back-button-div">
            <Link className="back-link" to="/dash">
              <a href="" className="back-button">
                <FontAwesomeIcon
                  className="fa"
                  icon={faCaretLeft}
                  style={{ color: "#34465c96", paddingTop: '-10px' }}
                />{" "}
              </a>

              Available Courses / {courseName}
            </Link>
          </div>

          <div className="course-header">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="course-title">
                  <h2>Course Title - {courseName}</h2>
                </div>
                <div className="course-info">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="course-info-1">
                        <FontAwesomeIcon
                          className="fa"
                          icon={faBookmark}
                          style={{ color: "#34465c96" }}
                        />{" "}
                        <h2>Category - {category}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="course-info-2">
                        <FontAwesomeIcon
                          className="fa"
                          icon={faUser}
                          style={{ color: "#34465c96" }}
                        />{" "}
                        <h2>Instructor - {instructor1}</h2>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="course-info-3">
                        <h2>4 Reviews - </h2>
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-sm-12">
                <div>

                  <h2 onClick={handleButtonClick12} className="click-here1" style={{ cursor: 'pointer' }}>
                    {isVisible ? 'Hide Link' : 'Click to copy Link'}
                  </h2>
                  {isVisible && (
                    <div>
                      {/* Your content goes here */}
                      {meetingLink1 ? (<p className="click-here1">{meetingLink1}</p>) : (
                        <p className="click-here1">no meeting in this week</p>)}
                    </div>
                  )}
                  <div className="zoom-link">
                    <div className="zoom-logo">
                      <img src={zoom_logo} alt="" />
                    </div>
                    <br></br>
                    <h2 className="time">{formatDate(recTime)}</h2>
                  </div>


                </div>
              </div>

              <div className="col-lg-4 col-md-8 col-sm-12">
                <div className="total-content">
                  <h3>Total Content</h3>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <h2>3 / 10 Weeks</h2>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `30%` }}></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <h2>7 / 10 Assignments</h2>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `70%` }}></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <h2>100 / 250 Tokens</h2>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `40%` }}></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <h2>1 / 4 Quiz</h2>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `25%` }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="week-content">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="week-list">
                  <ul>
                    {weeks.map((week) => (
                      <li
                        key={week}
                        className={week === selectedWeek ? "active-week" : ""}
                        onClick={() => handleWeekClick(week)}
                      >

                        <h2>Week - {week + 1}</h2>
                        <p>Updated on {formatDate(updated)}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12">
                <div className="week">
                  <h3>Week {selectedWeek + 1} Content</h3>
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                      <div className="items-required">
                        <div className="items-required-title">
                          <h2>Items Required</h2>
                        </div>
                        <div className="items-required-text">
                          <h2>Inks: {inks}</h2>
                        </div>
                        <div className="items-required-text">
                          <h2>Needles: {needles}</h2>
                        </div>
                        <div className="items-required-text">
                          <h2>Standard Material: {material}</h2>
                        </div>
                      </div>

                      <div className="lecture-design">
                        <h2 className="segment-title">Lecture {lectureTitle}</h2>
                        <div className="segment-image">
                          {recording?.fileName ? (
                            // Render the profile picture if fileName is not null
                            <video controls width="470">
                              <source src={'https://api.mastertattooinstitute.com/' + recording.fileName} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            // Render another picture or a default image when fileName is null
                            <img src={img1} className="no_img" alt="Default" />
                          )}{" "}
                        </div>
                        <p className="segment-text">

                        </p>
                        <h2 className="segment-title">Lecture - Description</h2>
                        <h2 className="segment-title">{weekdiscription}</h2>
                        <div className="segment-image">
                          {cover ?
                            (<img

                              src={'https://api.mastertattooinstitute.com/' + cover.fileName}
                              alt="Course Profile"
                            />) : (
                              <img src={img1} alt="" />)}
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <div className="upload-file">
                        <div className="upload-file-title">
                          <h2>Upload File</h2>
                        </div>
                        <div className="upload-file-browse" style={{ cursor: 'pointer' }} onClick={handleImageClick}>
                          <FontAwesomeIcon
                            className="fa"
                            icon={faFile}
                            style={{ color: "#283c55", height: "50px" }}
                          />{" "}
                          <label class="upload-file-text" style={{ cursor: 'pointer' }}>

                            <h3>Browse</h3>
                          </label>
                          <div className="mnbvc">
                            {imageName ?
                              (<h4 className="mnbvc">{imageName}</h4>) : (
                                <h4 className="mnbvc">no image uploaded</h4>)}
                          </div>

                        </div>
                        <div className="upload-file-button">
                          {!isUploaded && (
                            <button onClick={uploadAssignment}>
                              Upload now
                            </button>
                          )}
                          <button onClick={checkResult}>

                            Check Result

                          </button>
                        </div>
                      </div>

                      <div className="upload-file">
                        <div className="upload-file-title">
                          <h2>Quiz</h2>
                        </div>

                        <div className="upload-file-button1">
                          <button onClick={handleQuizClick} style={{
                            textDecoration: "none",
                            color: "white",
                            fontWeight: "600",
                          }}>

                            Start Now

                          </button>
                        </div>
                      </div>

                      {/* <div className="lessons">
                      <div className="lessons-title">
                        <h2>Lessons</h2>
                      </div>
                      <div className="lessons-text">
                        <div className="lessons-text-1">
                          <h2>Bootcamp Theory/Practical Lesson 1</h2>
                        </div>
                        <div className="lessons-text-2">
                          <h3>LESSON</h3>
                          <h2>120 Minutes</h2>
                          <FontAwesomeIcon
                            className="fa faLock"
                            icon={faLock}
                            style={{ color: "#34465c" }}
                          />{" "}
                        </div>
                      </div>
                      <div className="lessons-text">
                        <div className="lessons-text-1">
                          <h2>Bootcamp Theory/Practical Lesson 1</h2>
                        </div>
                        <div className="lessons-text-2">
                          <h3>LESSON</h3>
                          <h2>120 Minutes</h2>
                          <FontAwesomeIcon
                            className="fa faLock"
                            icon={faLock}
                            style={{ color: "#34465c" }}
                          />{" "}
                        </div>
                      </div>
                      <div className="lessons-text">
                        <div className="lessons-text-1">
                          <h2>Bootcamp Theory/Practical Lesson 1</h2>
                        </div>
                        <div className="lessons-text-2">
                          <h3>LESSON</h3>
                          <h2>120 Minutes</h2>
                          <FontAwesomeIcon
                            className="fa faLock"
                            icon={faLock}
                            style={{ color: "#34465c" }}
                          />{" "}
                        </div>
                      </div>
                      <div className="lessons-text">
                        <div className="lessons-text-1">
                          <h2>Bootcamp Theory/Practical Lesson 1</h2>
                        </div>
                        <div className="lessons-text-2">
                          <h3>LESSON</h3>
                          <h2>120 Minutes</h2>
                          <FontAwesomeIcon
                            className="fa faLock"
                            icon={faLock}
                            style={{ color: "#34465c" }}
                          />{" "}
                        </div>
                      </div>
                      <div className="lessons-text">
                        <div className="lessons-text-1">
                          <h2>Bootcamp Theory/Practical Lesson 1</h2>
                        </div>
                        <div className="lessons-text-2">
                          <h3>LESSON</h3>
                          <h2>120 Minutes</h2>
                          <FontAwesomeIcon
                            className="fa faLock"
                            icon={faLock}
                            style={{ color: "#34465c" }}
                          />{" "}
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="completeCourse">
            {isConditionTrue1 && <button className="upload-file-butto" onClick={handleCompleteCourse}>Complete Course</button>}
            {!isConditionTrue1 && <button className="upload-file-butto">Complete Weeks Content</button>}
          </div>
        </div>
      )}
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />


    </div>

  );
}
