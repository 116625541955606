import React,{useEffect,useState} from "react";
import "./profil.css";
import NAV from "./navbar";
import BIN from "../asset/WELCOME BAR.png";
import PROF from "../asset/Ellipse 47.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FRAME from "../asset/Frame 3619.png";
import { faContactCard } from "@fortawesome/free-solid-svg-icons";
import { faBrush } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faFileText } from "@fortawesome/free-solid-svg-icons";

export default function Profile() {
  const [courses, setcourse] = useState([]);

  const id = localStorage.getItem("info1");
  const [courseLenght,setLenght]=useState('');
  const [loading, setLoading] = useState(true);
 // console.log(id);

  useEffect(() => {
    const token1=localStorage.getItem("token");
    fetch(`https://api.mastertattooinstitute.com/api/v1/studentCourse/student/${id}/fetch`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,
       
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setcourse(data.courses);
        setLenght(data.courses.length);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [id]);
  const ProfilePic=localStorage.getItem('prifilepicture');
  const Studentname=localStorage.getItem('Fullname');
  const extractFirst22Words = (paragraph) => {
    return paragraph.slice(0, 10);
  };

  return (
    <div className="background-image5">
      <NAV />
      <br></br>
      {loading ? (
        <div class="container1">
          <div class="banner">
            LOADING
            <div class="banner-left"></div>
            <div class="banner-right"></div>
          </div>
        </div>


      ) : (

      <div className="wlcom" style={{marginTop:'70px'}}>
        <img src={BIN} className="bin"></img>
        {ProfilePic? (
        <img
        className="prof"
          src={'https://api.mastertattooinstitute.com/'+ProfilePic}
          alt="User Profile"
        />):(
          <img
        className="prof"
          src={PROF}
          alt="User Profile"
        />
        )}
    
        <div className="e">
          <h6 style={{fontSize:'20px'}}>{Studentname}</h6>
          
        </div>
        <br></br>
        <br></br>
        <div style={{ display: "flex" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6>
            <Link
              
              to="/profile"
              className="profile-hover"
            >
              About Me
            </Link>
          </h6>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h6>
            <Link
              
              to="/mycourses"
              className="profile-hover isactiver"
            >
              My Content
            </Link>
          </h6>
        </div>
        <hr></hr>
        <div className="container">
          <div className="divider">
        
<h4 className="lenght">{courseLenght} <span className="inTotal">Course in total</span></h4>
<div className="icon-text icon-text1 mnbvcx">
              <p style={{ marginLeft: "10px" }}>
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faGlobe}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Published
              </p>
              <p style={{ marginLeft: "20px" }}>
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faCalendar}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Created on August 8, 2023{" "}
              </p>
              <p className="mkl">
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faEye}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Public{" "}
              </p>
              <p className="mkl1">
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faFileText}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Course{" "}
              </p>
            </div>
            </div>
            </div>
          <div className="container">
            <div className="row">
            {courses.map((item, index) => (
              <div className="col-lg-3 col-md-6 col-sm-12">
              <div class="card">
                <div class="card_image">
                  {item?.course?.cover?.fileName?
                  (<img
                  
                      src={'https://api.mastertattooinstitute.com/'+item.course.fileName}
                      alt="Course Profile"
                    />):(
                  <img src={FRAME} className="frame_image"  />)}
                </div>
                <div class="card_content">
                  <h2 class="card_title">Course Title - {item.course.title}</h2>
                  <div className="new-div1">
                  <p class="card_text icon-text">
                    <FontAwesomeIcon
                      className="fa"
                      icon={faContactCard}
                      style={{ color: "#85A3AB", marginTop: "4px" }}
                    />
                    &nbsp;27 lectures
                    </p>
                    <p  class="card_text icon-text">
                    <FontAwesomeIcon
                      className="fa"
                      icon={faBrush}
                      style={{
                        color: "#85A3AB",
                        marginTop: "4px",
                        marginLeft: "0px",
                      }}
                    />
                    &nbsp;{item.course.category}
                  </p>
                  </div>
                  <hr style={{ color: "#85A3AB" }}></hr>
                  {/* <p class="card_text icon-text">
                    Complition Rate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p className="hr32"
                     
                    ></p>
                    &nbsp;&nbsp;&nbsp;Student Reaction<br></br>
                  </p> */}
                  <h3 className="deswq1">{extractFirst22Words(item.course.description)}...</h3>
                  <hr style={{ color: "#85A3AB" }}></hr>

                  <button class="btn card_btn">
                    <FontAwesomeIcon
                      className="fa"
                      icon={faClock}
                      style={{ color: "#85A3AB", marginTop: "4px" }}
                    />
                    &nbsp;{item.status}
                  </button>
                </div>
              </div>
          
           <br/>
         </div>
          ))} 
         </div>
        </div>

      </div>
        )}
      <br></br>
    
    </div>
  );
}
