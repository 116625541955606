import React, { useState, useEffect } from "react";
import "./course.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faFileText } from "@fortawesome/free-solid-svg-icons";
import FRAME from "../asset/Frame 3619.png";
import { faContactCard } from "@fortawesome/free-solid-svg-icons";
import { faBrush } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import NAV from "../compnent/navbar";
import Lock from "../asset/material-symbols_lock.svg"
import Unlock from "../asset/Vector (1).png";
import World from "../asset/tabler_world.png";
import { Link, useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";

export default function Courses() {

  const [courses, setCourses] = useState([]);
  const [isLocked1, setIsLocked] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    // Make the API request using fetch
    const token1 = localStorage.getItem("token");
    fetch('https://api.mastertattooinstitute.com/api/v1/course', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token1}`,

      },
    })
      .then(response => response.json())
      .then(data => {
        setCourses(data);
        setIsLocked(data.isLocked);
        console.log(data.isLocked)
        setLoading(false);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);
  const extractFirst22Words = (paragraph) => {
    return paragraph.slice(0, 10);
  };


  return (
    <div className="bg_image">
      <NAV />
      <br />
      {loading ? (
        <div class="container1">
          <div class="banner">
            LOADING
            <div class="banner-left"></div>
            <div class="banner-right"></div>
          </div>
        </div>


      ) : (

        <div className="container ssl">
          <div className="backtodashboard">
            <Link to={'/dash'} style={{ textDecoration: 'none' }}>
              <div className="inner-flexer">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 7 14" fill="none" className="back-dash">
                  <path d="M6 12.4479L1 6.72396L6 1" stroke="#283C55" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <h3 className="back-texture">Back to Dashboard</h3>
              </div>
            </Link>
          </div>
          <div className="inner-dash">
            <br></br>
            <br></br>
            <h1 className="course-heading">Available Courses on MTI</h1>
            <br></br>
            <div className="icon-text icon-text1">
              <p style={{ marginLeft: "10px" }}>
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faGlobe}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Published
              </p>
              <p style={{ marginLeft: "20px" }}>
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faCalendar}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Created on August 8, 2023{" "}
              </p>
              <p className="mkl">
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faEye}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Public{" "}
              </p>
              <p className="mkl1">
                {" "}
                <FontAwesomeIcon
                  className="fa"
                  icon={faFileText}
                  style={{ color: "#283C5566", marginRight: '10px' }}
                />{" "}
                Course{" "}
              </p>
            </div>
          </div>

          <hr></hr>
          <br></br>


          <div className="container">
            <div className="row">
              {courses.map(course => (
                <div className="col-lg-3 col-md-6 col-sm-12">

                  <div class="card">
                    <div class="card_image">
                      {course?.cover?.fileName ?
                        (<img

                          src={'https://api.mastertattooinstitute.com/' + course.cover.fileName}
                          alt="Course Profile"
                          className="frame_image"
                        />) : (
                          <img src={FRAME} className="frame_image" />)}
                      <div className="world">
                        <p><img src={World} className="wes" />&nbsp;&nbsp;&nbsp;Publish</p>
                      </div>
                      <div className="lock">
                        {course.isLocked === true ? (
                          <img src={Lock} />

                        ) : (
                          <img src={Unlock} alt="Unlocked" />

                        )}
                      </div>
                    </div>

                    <div class="card_content">
                      <h2 class="card_title">{course.title}</h2>
                      <div className="new-div1">
                        <p class="card_text icon-text">
                          <FontAwesomeIcon
                            className="fa"
                            icon={faContactCard}
                            style={{ color: "#85A3AB", marginTop: "4px" }}
                          />
                          &nbsp;{course.weeks.length} weeks
                        </p>
                        <p class="card_text icon-text">
                          <FontAwesomeIcon
                            className="fa"
                            icon={faBrush}
                            style={{
                              color: "#85A3AB",
                              marginTop: "4px",
                              marginLeft: "0px",
                            }}
                          />
                          &nbsp;{course.category}
                        </p>
                      </div>
                      <hr style={{ color: "#85A3AB" }} className="hr1"></hr>
                      {/* <p class="card_text icon-text new11">
                    Complition Rate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <p className="hr32"
                     
                    ></p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Student Reaction<br></br>
                  </p> */}
                      <h6 className="des-course">{extractFirst22Words(course.description)}...</h6>
                      <hr style={{ color: "#85A3AB" }}></hr>

                      <button class="btn card_btn">
                        <FontAwesomeIcon
                          className="fa"
                          icon={faClock}
                          style={{ color: "#85A3AB", marginTop: "4px" }}
                        />
                        &nbsp;Button
                      </button>
                    </div>
                  </div>
                  <br />
                </div>

              ))}

            </div>
          </div>
        </div>

      )}
      <br></br>
    </div>
  );
}
