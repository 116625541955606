import React, { useState ,useEffect} from 'react';
import "./quiz.css";
import zoom_logo from "../asset/Zoom-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Batch from "../asset/batch.png";

export default function QuizResult() {
const {courseId}=useParams();
const{result}=useParams();
const{itemId}=useParams();

const[courseName,setName]=useState('');
const[instructor,setInstructor]=useState('');
const[category,setCategory]=useState('');
useEffect(() => {
  // Fetch the initial course data (for the first week)
  const token1 = localStorage.getItem("token");
  fetch(`https://api.mastertattooinstitute.com/api/v1/course/${courseId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token1}`,

    },
  })
    .then((response) => response.json())
    .then((data) => {
     setName(data.title);
     setInstructor(data.instructor.firstName);
     setCategory(data.category);
    })
    .catch((error) => {
      console.error("Error fetching course data: ", error);
    });
},);
  const navigate1=useNavigate();
const navigateCourse=()=>{
navigate1(`/coursedetail/${courseId}/${itemId}`)
}
    return (
      <>
        <div className="container tray">
          <div className="back-button-div">
            <a href="" className="back-button">
              <FontAwesomeIcon
                className="fa"
                icon={faCaretLeft}
                style={{ color: "#34465c96" }}
              />{" "}
            </a>
            <Link className="back-link" to={`/coursedetail/${courseId}/${itemId}`}>
              Back to Assigned Course
            </Link>
          </div>
          <div className="course-header">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="course-title">
                  <h2>Course Title - {courseName}</h2>
                </div>
                <div className="course-info">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="course-info-1">
                        <FontAwesomeIcon
                          className="fa"
                          icon={faBookmark}
                          style={{ color: "#34465c96" }}
                        />{" "}
                        <h2>Category - {category}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="course-info-2">
                        <FontAwesomeIcon
                          className="fa"
                          icon={faUser}
                          style={{ color: "#34465c96" }}
                        />{" "}
                        <h2>Instructor - {instructor}</h2>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="course-info-3">
                        <h2>4 Reviews - </h2>
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                        <FontAwesomeIcon
                          className="fa"
                          icon={faStar}
                          style={{ color: "#FEC107" }}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                {/* <div className="quiz-progress">
                  <div className="progress-wrapper">
                    <h2>
                    {currentQuestion + 1}/{totalQuestions} Question
                    </h2>
                    <h3>Quiz progress: {progressPercentage.toFixed(2)}%</h3>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="batch-flex">
            <img src={Batch}/>
            </div>
            <p className='Quizscore'>{result*10}</p>
            <p className='totalScore'>Your Total Score</p>
            <p className='scoreminimum'>Your pass with score above minimum, Congratulations!</p>
            <div className="batch-flexed">
            <button className="flesd"onClick={navigateCourse}>Back to Course</button>
            </div>
          </div>
         
         
        </div>
      </>
    );
  }
